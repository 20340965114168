@import "styles/theme.scss";

.wizard-body {
  overflow: hidden;
  .wizard-main {
    padding: 6em 6em 6em 6em;
    height: 100vh;
    .wizard-header {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0;
      .proposal-header {
        & > :first-child {
          padding-right: 32px;
          border-right: 1px solid $tab-border;
        }
      }
    }
    .page-header {
      .page-title-conatiner {
        display: flex;
        width: 65em;
        .page-title {
          margin-right: 0.5em;
          font-size: 40px;

          .page-title-info {
            background: $background-color;
            border-radius: 32px;
            padding: 0.2em 1em;
            font-size: $label-font-size;
            margin-left: 1em;
            font-weight: 500;
            vertical-align: middle;
          }

          @include rwd-min(320) {
            font-size: $large-text-font-size;
          }
          @include rwd-min(1024) {
            font-size: $large-text-font-size;
          }
        }
        .page-label {
          width: 28em;
          padding: 1px 0;
          font-size: 13px;
          color: $primary-color-text;
          font-weight: 400;
          @include rwd(320) {
            padding: 0;
          }
          @include rwd(2560) {
            width: 100%;
          }
        }

        @include rwd(2560) {
          display: block;
          width: 100%;
        }
      }
    }
    .site-page-header {
      padding: 0;
      .ant-page-header-heading > .ant-page-header-heading-left > span {
        font-size: $title-font-size;
      }
      .site-page-header-divider {
        min-width: 0;
        width: 3em;
        border-top: 1px solid $secondary-color;
        margin: 0;
      }
    }

    .wizard-content {
      .site-layout-background {
        height: 100%;
        margin: 1.5em 0;
        border: 1px solid none;
        background: #ffffff;
        box-shadow: $box-shadow;
        border-radius: 4px;

        .ant-layout-content {
          display: flex;
          flex-direction: column;
          position: relative;
          overflow: hidden;

          @include rwd(320) {
            overflow: scroll;
          }

          .wizard-content-container {
            @include custom-scrollbar;
            overflow-y: scroll;
            overflow-x: hidden;
            flex: 1;
            padding-bottom: 0!important;

            .wizard-loader {
              position: relative;
            }

            .wizard-inner-content {
              padding: 2em;
              width: 50em;
              label {
                color: $label-color-light;
              }
              > .input-container {
                width: 100%;
                margin-bottom: 1.2em;
              }

              @include rwd-min(2560) {
                padding: 2em;
                width: 75em;
              }

              @include rwd(1440) {
                padding: 2em;
                width: 75em;
              }
            }

            .project-description {
              height: 5.5em;
            }

            .project-goals {
              height: 5em;
            }

            &.wizard-language-rtl {
              .wizard-inner-content {
                .input-container,
                .wix-client-name-dropdown-container {
                  .ant-select-selection-placeholder,
                  .input-sub-label {
                    text-align: right;
                  }
                  input,
                  textarea,
                  .ant-row {
                    direction: rtl;
                  }
                  #wix-client-name-dropdown-button {
                    .ant-col {
                      img {
                        margin-right: 0;
                        margin-left: 0.5em;
                      }
                    }
                  }
                }
              }

              .tablist-container {
                .tablist-prelist-container {
                  .tablist-prelist {
                    .tablist-prelist-input {
                      .input-container {
                        input,
                        .ant-input-affix-wrapper {
                          direction: rtl;
                        }
                      }
                    }

                    .tablist-prelist-content {
                      .ant-list-item {
                        direction: rtl;
                        text-align: right;
                        .ant-list-item-meta-title {
                          img {
                            margin-right: 0;
                            margin-left: 0.6em;
                          }
                        }
                      }
                    }
                  }
                }

                .tablist-content {
                  .tablist-sortlist-container {
                    .tablist-sortlist-content {
                      .tablist-input-container {
                        .input-container,
                        .select-container {
                          .ant-row {
                            .input-label {
                              direction: rtl;
                              text-align: right;
                            }
                          }
                          input,
                          textarea,
                          .ant-select-selection-placeholder {
                            direction: rtl;
                          }
                          .ant-select-item {
                            direction: rtl;
                            text-align: right;
                          }
                          &.super-textarea textarea {
                            padding-right: 0 !important;
                          }
                        }
                      }

                      .tablist-input-description {
                        direction: rtl;
                        text-align: left;
                      }
                    }
                  }

                  .sortlist-price-fields {
                    .input-container {
                      direction: rtl;
                      max-width: 150px;
                    }
                  }
                }
              }

              .tablist-input-container {
                .input-container {
                  direction: rtl;
                }
              }
            }
          }

          .wizard-content-options {
            padding: 16px;
            align-items: center;
            display: flex;
            justify-content: space-between;
            bottom: 0;
            width: 100%;
            border-top: 1px solid rgba(0, 0, 0, 0.06);
            background: $white-color;
            z-index: 10;

            @include lg {
              button {
                min-width: 5em !important;
              }
            }
            @include rwd(1024) {
              height: 6.5em;
              .ant-row {
                button,
                .undo-redo {
                  min-width: 7em !important;
                }
              }
            }

            @include rwd(768) {
              height: 6.5em;
              .ant-row {
                flex-flow: row;
                button,
                .undo-redo {
                  min-width: 7em !important;
                }
              }
            }

            @include xs {
              flex-direction: column;
              height: 9.5em;
              .ant-row {
                width: 100%;
                justify-content: space-between;
                button,
                .undo-redo {
                  min-width: 9em !important;
                  font-size: 10px;
                }
              }
            }

            @include rwd(320) {
              flex-direction: column;
              height: 9.5em;
              .ant-row {
                width: 100%;
                justify-content: space-between;
                button,
                .undo-redo {
                  min-width: 100% !important;
                  font-size: 10px;
                }
              }
            }

            button {
              min-width: 11em;
              height: 45px;
              justify-content: center;
              font-weight: 600;
            }

            .undo-redo {
              padding: 0 25px;
              margin: auto 0;
              justify-content: space-between;
              display: flex;
              width: 7.5em;
            }
            .wizard-quit,
            .wizard-reset {
              background: $tab-background;
              border-radius: 4px;
              border: none;
              color: $primary-color;
              &:disabled {
                opacity: 0.6;
              }
            }

            .wizard-quit {
              margin-right: 1em;
              direction: rtl;
              display: flex;
              min-width: 11em;
              align-items: center;
              span {
                margin: auto;
              }
            }
            .create-proposal {
              margin: 0 !important;
              margin-right: 3px !important;
            }

            .wizard-continue {
              background-color: $secondary-color;
              border: unset !important;
              box-sizing: border-box;
              box-shadow: $box-shadow;
              border-radius: 4px;
              direction: rtl;
              display: flex;
              align-items: center;
              padding: 8px 8px 8px 16px;
              span {
                margin: auto;
                padding: 0;
              }
              .wizard-create-button {
                background: rgba(255, 255, 255, 0.2);
                height: 28px;
                width: 28px;
                margin: auto 0em;
                margin-left: 0.5em;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              &:hover {
                opacity: 0.85;
              }

              &.wizard-continue-disabled {
                pointer-events: none;
                cursor: not-allowed;
                opacity: 0.8;
                background: $tab-background;
                border: none;
                color: $primary-color;
              }
            }

            
          }
        }
      }
    }

    .sidebar-wizard,
    .sidebar-wizard-collapsed {
      padding: 2em;
      box-shadow: $box-shadow;
      border-radius: 0px 6px 6px 0px;
      background-color: $primary-color;
      overflow: hidden;
      position: relative;

      .ant-layout-sider-children {
        @include custom-scrollbar;
        overflow-y: scroll;
        position: absolute;

        .ant-steps-item {
          height: 80px;

          > .ant-steps-item-container {
            .ant-steps-item-tail {
              top: -34px;
              height: 190%;
              left: 19px;
              &::after {
                background-color: $primary-color-light;
              }
            }

            .ant-steps-item-content {
              & > .ant-steps-item-title {
                color: $white-color;
                font-size: $label-font-size;
                font-weight: 300;
                letter-spacing: 0.5px;
              }
              @include sm {
                overflow: visible;
                .ant-steps-item-title {
                  font-size: 9px;
                }
              }
            }

            .ant-steps-item-icon {
              margin: 0px 8px 0px 4px;
              background-color: $primary-color-light;
              .ant-steps-icon {
                color: $white-color !important;
              }
            }

            .ant-steps-item-tail::after {
              width: 2px;
            }

            &:hover .ant-steps-item-icon {
              color: $white-color !important;
              border-color: $white-color !important;
            }
          }
        }

        .ant-steps-item-active {
          height: 110px;

          .ant-steps-item-container {
            .ant-steps-item-content > .ant-steps-item-title {
              font-weight: 600;
            }
            .ant-steps-item-tail::after {
              background-color: $white-color;
              height: 90%;
            }
            .ant-steps-item-icon {
              background-color: $white-color;
              width: 40px;
              margin: 0px 8px 0px 0px;
              height: 40px;
              .ant-steps-icon {
                color: $primary-color-text !important;
                vertical-align: sub;
                font-weight: 700;
              }
            }
          }
        }

        .ant-steps-item-finish > .ant-steps-item-container {
          .ant-steps-item-icon {
            background-color: $completed-color;
          }

          .ant-steps-item-tail::after {
            background-color: $completed-color;
          }
        }
      }
    }

    .sidebar-wizard-collapsed {
      padding: 2em 0.5em;
    }
  }

  .sidebar {
    display: none;
  }

  label {
    .asterisk {
      color: $form-error-color;
    }
  }
  .input-container {
    &.error {
      &.autocomplete-wrapper {
        .ant-select-selector {
          border-color: $form-error-color!important;
        }

        .ant-select-focused .ant-select-selector {
          box-shadow: $error-box-shadow;
        }
      }

      &>.ant-input {
        border-color: $form-error-color;

        &:focus {
          box-shadow: $error-box-shadow;
        }
      }
    }
  }
}

.ant-tooltip.wizard-tooltip-email {
  width: auto;
  max-width: 100%;
  font-size: 12px;
  &.settings-tip {
    max-width: 20%;
  }

  .ant-tooltip-inner {
    color: $primary-color-text !important;
    text-align: center;
  }
}

@include rwd(1400) {
  .wizard-body {
    .wizard-main {
      padding: 5em 2em 2em 2em;
    }
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .wizard-body {
    .wizard-main {
      padding: 8em 2em 8em 2em;
    }
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .wizard-body {
    .wizard-main {
      padding: 6em 1em 4em 1em;
    }
  }
}

@media (min-width: 200px) and (max-width: 480px) {
  .wizard-body {
    .wizard-main {
      padding: 6em 1em 4em 1em;
    }
  }
}

.ant-select-dropdown {
  .wizard-language-rtl {
    input,
    .ant-select-item {
      direction: rtl;
      text-align: right;
    }
  }
}
