@import "styles/theme.scss";

.user-activity-list {
  background: white;
  padding: 1em 2.5em 1em 2.5em;
  box-shadow: $box-shadow;
  border-radius: 4px;
      
  .ant-divider {
    .ant-divider-inner-text {
      background: $tab-background;
      border-radius: 100px;
      font-weight: 500;
      font-size: 13px;
      line-height: 2.5em;
      color: $label-color-light;
      padding: 2px 2em;
      margin: 0 2em;
    }
  }
  .ant-list.ant-list-split > .ant-spin-nested-loading > .ant-spin-container > .ant-list-items {
    .ant-list-item > .ant-list-item-meta {
      align-items: center;
      .ant-list-item-meta-avatar {
        width: 30%;
        .user-activity-list-avatar-container {
          display: flex;
          .ant-avatar {
            min-width: 50px;
            width: 50px;
            height: 50px;
            line-height: 50px;
            box-shadow: $box-shadow-avatar;
          }
          .user-activity-list-avatar-content {
            margin: auto 1em;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            flex-grow: 1;
            a {
              color: $primary-color-text;
              margin-bottom: 3px;
              font-size: 18px;
              font-weight: 600;
            }
            p {
              margin: 0;
              color: $label-color-light;
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
      }
      .ant-list-item-meta-content {
        .ant-list-item-meta-title {
          span {
            color: $primary-color-text;
            font-size: 16px;
            font-weight: 400;
            text-transform: capitalize;
            a {
              font-weight: 600;
              color: $primary-color-text;
            }
          }
        }
      }
    }
  }
}
.view-all-button {
  padding: 6px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  top: auto;
  font-weight: 500;
  background-color: white;
  width: 100%;
  .button-action {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    span {
      margin-right: 5px;
      color: #F03F3B;
      transition-duration: .2s;
      transform: translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
      cursor: pointer;
      &:hover {
        color: #fc6e6c;
        transform: translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(1.0391) scaleY(1.0391);
      }
      &.color-red {
        svg {
          path {
            fill: #F03F3B;
          }
        }
      }
    }
  }
  .fade-patch {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
    height: 20px;
    width: 96%;
    display: block;
    position: absolute;
    top: -20px;
  }
}
