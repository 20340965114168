@import 'styles/theme.scss';

$header-height: 48px;

.publish-body {
  .sidebar {
    display: none;
  }
  .header {
    display: none;
  }
}

.not-found {
  display: flex !important;

  justify-content: center;
  align-items: center;
  height: 100vh;
  h1 {
    color: $primary-color-text !important;
    font-weight: 500;
    font-size: 24px;
  }
}

.publish {
  height: 100vh;
  transition: height 0.4s;

  &.ant-layout.ant-layout-has-sider {
    flex-direction: column;
  }

  .publish-header {
    position: relative;
    transition: height 0.4s;
    .proposal-header {
      position: relative !important;
      transition: height 0.4s;
      overflow-y: hidden;
    }
    padding: 0;
    z-index: 3;
    .publish-header-toggle-icon {
      position: absolute;
      top: 90px;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      cursor: pointer;
      transition: top 0.4s;
      svg {
        width: 104px;
        height: 60px;
      }
      svg > path {
        transform-origin: center 28px;
        transition: transform 0.4s;
      }
    }
  }

  .publish-content-table {
    flex: 0 0 64px !important;
    max-width: 64px !important;
    min-width: 64px !important;
    width: 64px !important;
    height: auto;
    position: fixed;
    top: 64px;
    z-index: 13;
    &.ant-layout-sider-is-collapsed {
      .content-table-menu {
        transform: translateX(-110%);
      }

      &.language-rtl {
        .content-table-menu {
          transform: translateX(110%);
        }
      }
    }

    .content-table-menu {
      height: calc(100dvh - 122px);
      @media screen and (min-width: 772px) {
        height: calc(100dvh - 149px);
      }
      @media (max-width: 500px) {
        &.short {
          height: calc(100dvh - 171px);
        }
      }
    }

    &-preview {
      height: calc(100vh - 90px);
      top: 0;
    }

    .ant-layout-sider-children {
      .content-table-header {
        cursor: pointer;
        height: 45px;
        padding: 0.8em 1.3em;
      }
    }

    &.language-rtl {
      right: 0;
      .ant-menu {
        .ant-menu-item {
          direction: rtl;
          text-align: right;
        }
      }
    }
  }

  .publish-content {
    flex-grow: 1;
    transition: min-height 0.4s;
    overflow: auto;
    padding: 50px 20px;
    & > div {
      max-width: 900px;
      margin: 0 auto;
      background: white;
    }

    // to add extra gap on top of proposal
    @include lg {
      .gap {
        background-color: transparent !important;
        &.one {
          height: 15px;
        }
        &.two {
          height: 60px;
        }
      }
    }
  }

  .client-preview {
    height: auto;
    .content-table-menu {
      height: calc(100dvh - 58px);
      @media screen and (min-width: 772px) {
        height: calc(100dvh - 85px);
      }
      @media (max-width: 500px) {
        &.short {
          height: calc(100dvh - 108px);
        }
      }
    }
  }

  &.minimize {
    .publish-content-table {
      top: 0px;
      transition: top 0.4s;
    }
    .content-table-menu {
      height: calc(100dvh - 85px);
      @media (max-width: 500px) {
        &.short {
          height: calc(100dvh - 107px);
        }
      }
    }
    .publish-header,
    .proposal-header {
      height: 0px !important;
    }
    .publish-header-toggle-icon {
      top: 32px;

      svg > path {
        transform: rotate(180deg);
      }
    }
  }
}

.proposal-header {
  .ant-btn.cancel {
    display: flex;
    align-items: center;
  }
  .ant-divider {
    margin: 0px;
  }
}

.PDF {
  background: white;
  height: auto;
  overflow-x: hidden;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  position: relative;
  zoom: 0.85;

  .pdf-signed-info {
    display: block;
  }

  #root {
    height: auto;
    .ant-layout {
      height: auto;

      nobreak > div.proposal-signaturesection {
        break-inside: avoid;
        break-after: avoid;
        break-before: avoid;
      }

      nobreak .overview,
      nobreak .terms,
      nobreak .whyme,
      nobreak .whatnext,
      nobreak .sig,
      nobreak > div,
      div[data-nobreak] {
        padding-top: 30px;
        break-inside: auto;
        break-after: auto;
        break-before: auto;

        table {
          break-inside: auto;
          break-after: auto;
          break-before: auto;
        }

        figure {
          display: block;
        }

        tr,
        td {
          break-inside: auto;
          break-before: auto;
          break-after: auto;
        }
        thead {
          display: table-header-group !important;
        }
        tfoot {
          display: table-footer-group !important;
        }
      }

      .pdf-avoid-break {
        break-inside: avoid;
        page-break-inside: avoid;
      }

      #section-content-container.imported-section-content-container {
        display: inline-block;
        .proposal-headersection {
          break-inside: auto;
          break-after: avoid;
          break-before: avoid;
          page-break-inside: auto;
        }

        .proposalblock-image {
          padding-top: 0px !important;

          .image-publish-container {
            break-inside: auto !important;
            break-after: avoid !important;
            break-before: avoid !important;
            page-break-inside: auto !important;
          }
        }
      }

      .ant-layout-content {
        -ms-flex: auto;
        flex: auto;
        height: auto;
      }

      .publish {
        height: auto;
        overflow-x: hidden;
        .publish-content {
          padding: 0;
          flex-grow: 0;
          transition: auto;
          overflow-x: hidden;
          padding: 0px !important;
          max-width: 10000px !important;
          min-width: 0 !important;
          & > div {
            max-width: 100%;
          }
        }
        .publish-footer {
          display: none;
        }
      }
    }
  }
}

.PDF,
.client-preview {
  .publish-header {
    display: none;
  }
}

.PDF {
  .gap.one {
    display: none;
  }
  .testimonial-item {
    .avatar {
      // mac dont support partial transparent shadow
      box-shadow: unset !important;
    }
  }
}

.language-rtl {
  #section-content-container {
    .simple-section-content {
      p {
        white-space: inherit;
        overflow-wrap: break-word;
        text-align: right;
      }

      ul {
        li {
          margin-right: 24px;
          margin-left: 0em;
          white-space: inherit;
          &:before {
            left: unset !important;
            right: -65px;
            width: 30px;
          }
          &.RichEditor-unordered-list-item-hyphen {
            &:before {
              right: -65px;
            }
          }

          &.RichEditor-unordered-list-item-hyphen,
          &.RichEditor-unordered-list-item-check {
            list-style-type: none;
            padding-right: 10px;
          }

          ul li {
            list-style-type: circle;
            ul li {
              list-style-type: square;
            }
          }
        }
      }

      ol {
        li {
          margin-left: 0 !important;
          margin-right: 17px;
          padding-right: 15px;
          padding-left: 0;
          white-space: inherit;

          &.RichEditor-unordered-list-item-hypen::before {
            counter-increment: ol0;
            content: url('https://dr08qaykn9isr.cloudfront.net/images/Webp.net-resizeimage+(1).png') !important;
          }

          &.RichEditor-unordered-list-item-check::before {
            counter-increment: ol0;
            content: url('https://dr08qaykn9isr.cloudfront.net/images/Webp.net-resizeimage.png') !important;
          }

          &::before {
            text-align: right;
            left: unset;
            right: 0;
            transform: translateX(100%);
          }
          ol li {
            margin-right: 40px !important;
            padding-right: 5px;

            &.RichEditor-ordered-list-item-alpha {
              margin-right: 40px !important;
              padding-right: 10px;
            }

            ol li {
              margin-right: 40px !important;
              padding-right: 16px;

              &.RichEditor-ordered-list-item-alpha {
                padding-right: 15px;
              }
              ol li {
                &.RichEditor-ordered-list-item-alpha {
                  margin-right: 40px !important;
                  padding-right: 20px;
                }
                ol li {
                  &.RichEditor-ordered-list-item-alpha {
                    padding-right: 25px;
                  }
                }
              }
            }
          }
        }
      }

      .texblockbody {
        ol,
        ul {
          text-align: right;
        }
        .super-li {
          padding-right: 1.5em;
          &::before {
            right: 0.2em;
            text-align: right;
          }
        }
        .pkg-name {
          text-align: right;
        }
      }

      table tbody tr {
        .name,
        .grey1 {
          text-align: right;
          p {
            white-space: pre-wrap;
          }
        }
        .price {
          text-align: left;
          direction: initial;
        }
        .time {
          text-align: left;
        }
        &.timerow {
          td:nth-child(1) {
            flex: 0.7;
          }
          td:nth-child(2) {
            flex: 0.3;
          }
        }
      }
    }
  }
}


.history-preview {
  .display-rtl .publish-content-table {
    right: 270px !important;
  }
}